<template>
      <v-dialog 
        v-model="dialog" 
        max-width="700px" 
        v-on:click:outside="cancelMethod"
        :persistent="loading"
        >
          <v-card>
            <v-card-title class="text-h6 d-flex justify-center">{{dialogText}}</v-card-title>
            <v-card-subtitle class="text-subtitle-1 d-flex justify-center" v-if="dialogSubtext">{{dialogSubtext}}</v-card-subtitle>
            <v-card-actions v-if="!loading">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancelMethod">{{cancelButtonText}}</v-btn>
              <v-btn color="blue darken-1" text @click="confirmMethod">{{confirmButtonText}}</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
            <v-card-actions v-else class="justify-center pb-6">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    :width="3"
                ></v-progress-circular>
            </v-card-actions>
          </v-card>
        </v-dialog>
</template>
<script>
export default {
    data: function() {
        return {

        };
    },
    props: {
        dialog: {
            type: Boolean,
            required: true
        },
        confirmButtonText: {
            type: String,
            default: 'OK'
        },
        cancelButtonText: {
            type: String, 
            default: 'Annulla'
        },
        dialogText: {
            type: String,
            required: true
        },
        dialogSubtext: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        cancelMethod () {
            if(!this.loading)
                this.$emit('c-dialog-cancel');
        },
        confirmMethod () {
            this.$emit('c-dialog-confirm');
        }
    }
}
</script>