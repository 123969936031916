import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"max-width":"700px","persistent":_vm.loading},on:{"click:outside":_vm.cancelMethod},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"text-h6 d-flex justify-center"},[_vm._v(_vm._s(_vm.dialogText))]),(_vm.dialogSubtext)?_c(VCardSubtitle,{staticClass:"text-subtitle-1 d-flex justify-center"},[_vm._v(_vm._s(_vm.dialogSubtext))]):_vm._e(),(!_vm.loading)?_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.cancelMethod}},[_vm._v(_vm._s(_vm.cancelButtonText))]),_c(VBtn,{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.confirmMethod}},[_vm._v(_vm._s(_vm.confirmButtonText))]),_c(VSpacer)],1):_c(VCardActions,{staticClass:"justify-center pb-6"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary","width":3}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }